html, body {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: var(--app-height);
  font-size: calc(10px + 2vmin);
  color: white;
}

/* .Sign_in_button {
  width: 100px;
  height: 50px;
  background-color: #282c34;
  color: white;
} */

.top{
  position: absolute;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #0c0c0c;
  bottom: 90%;
  height: 10%;
  width: 100%;
}

.chatbox{
  position: absolute;
  overflow-y: scroll;
  background-color: #1e1e24;
  bottom: 10%;
  height: 80%;
  width: 100%;
}

.wrapper{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 25%;
  bottom: 0;
  height: var(--app-height);
  width: 50%;
}

.send {
  background-color: #4a515f; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

textarea {
  line-height: 1.5;
  width: 100%;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  word-break: break-word;
  resize: none;
}

form {
  position: absolute;
  height: 10%;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  display: flex;
  font-size: 1.5rem;
}

form button {
  width: 20%;
  background-color: rgb(56, 56, 143);
}

img.emoji {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin: auto;
}

.chatbox::-webkit-scrollbar {
  width: 0.5rem;
}

.chatbox::-webkit-scrollbar-track {
  background: #292929;
}

.chatbox::-webkit-scrollbar-thumb {
  background: #717075;
  border-radius:2em;
}

.inp::-webkit-scrollbar {
  width: 0.5rem;
}

.inp::-webkit-scrollbar-track {
  background: #292929;
}

.inp::-webkit-scrollbar-thumb {
  background: #717075;
  border-radius:2em;
}

.Sign_in_button{
  width: 130px;
  height: 50px;
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-size: 20px;
  font-weight:500;
  color:#FFFFFF;
  background-color:#282c34;
  text-align:center;
  transition: all 0.2s;
  touch-action: manipulation;
  cursor: pointer;
}
.Sign_in_button:hover{
background-color:rgba(102, 104, 110, 0.322)  
}

#sign_out{
  width: 115px;
  height: 40px;
  margin-left: 50%;
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-size: 23px;
  font-weight:500;
  color:#FFFFFF;
  background-color:#282c34;
  text-align:center;
  transition: all 0.2s;
  touch-action: manipulation;
  cursor: pointer;
}
#sign_out:hover{
background-color:rgba(102, 104, 110, 0.322)  
}

img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 10px;
}

p {
  max-width: 50%;
  margin-top: 12px;
  margin-bottom: 0px;
  line-height: 20px;
  font-size: 15px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
  word-break: break-word;
}

.message {
  display: flex;
  align-items: center;
}

.grouped p{
  margin: 1px 60px;
  border-radius: 25px 8px 8px 25px;
}

.grouped.received p{
  margin: 1px 60px;
  border-radius: 8px 25px 25px 8px;
}

.scrollbottom {
  width: 40px;
  height: 40px;
  margin: auto;
  bottom: 15%;
  position: fixed;
  background-color: #32323b;
  color: #0b93f6;
  font-size: 25px;
  cursor: pointer;
  border-radius: 50%;
  border: #606775 solid 2px;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

#hour {
  background: none;
  color: rgb(110, 109, 109);
  font-size: 11px;
}

.newday {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(110, 109, 109);
  font-size: 15px;
}

.newdayline {
  border-color: rgb(110, 109, 109);
  border-width: 1px;
  border-style: solid;
  width: 42%;
  margin-left: 10px;
  margin-right: 10px;
}

.App-link {
  color: #61dafb;
}

@media screen and (orientation: landscape) and (max-height: 600px) {
  body {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .App-header {
    height: var(--app-width);
    width: var(--app-height);
  }
  .wrapper{
    left: 0;
    height: var(--app-width);
    width: var(--app-height);
  }
  #sign_out{
    width: 100px;
    height: 35px;
    font-size: 15px;
    margin-left: 25%;
  }
  img.emoji {
    width: 20px;
    height: 20px;
  }
  .top p{
    font-size: 18px;
  }
  .top img{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px;
  }
  p {
    max-width: 200px;
    margin-bottom: 12px;
    line-height: 20px;
    font-size: 15px;
    padding: 10px 20px;
  }
}

@media screen and (orientation: landscape) and (max-height: 380px) {
  .top p{
    font-size: 12px;
  }
}

@media screen and (max-height: 780px) {
  img.emoji {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .wrapper{
    left: 0;
    width: 100%;
  }
  #sign_out{
    width: 100px;
    height: 35px;
    font-size: 15px;
    margin-left: 25%;
  }
  img.emoji {
    width: 20px;
    height: 20px;
  }
  .top p{
    font-size: 15px;
  }
  .top img{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-left: 10px;
  }
  p {
    max-width: 200px;
    margin-bottom: 12px;
    line-height: 20px;
    font-size: 15px;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 380px) {
  .top p{
    font-size: 12px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
